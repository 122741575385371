import { useEffect, useState } from "react";
import { Button, Card, Steps, Spin } from "antd";

import { locale, Currency } from "./locale";
import ProjectsService, {
  Chemical,
  CreateProjectRequestBody,
  Dosing,
  ProductionType,
  ProjectType,
} from "../../services/projectService";
import Page from "../../hocs/Page";
import Header from "../../components/Header";

import BaseInfoStep from "./BaseInfoStep";
import CustomerService, { Customer } from "../../services/customerService";
import CurrentWaterStep from "./CurrentWaterStep";
import CurrentChemicalsStep from "./CurrentChemicalsStep";
import CurrentLabourStep from "./CurrentLabourStep";
import SystemSavingsStep from "./SystemSavingsStep";
import SystemWaterStep from "./SystemWaterStep";
import SystemDosingStep from "./SystemDosingStep";
import SystemChemicalsStep from "./SystemChemicalsStep";
import SystemStationsStep from "./SystemStationsStep";
import SystemUsersStep from "./SystemUsersStep";
import SystemServiceStep from "./SystemServiceStep";
import { generatePath, useNavigate, useParams, useLocation } from "react-router-dom";

import "./ProjectCreationPage.scss";
import { projectPagePath } from "../ProjectPage";

export const projectCreationPagePath = "/create-project";
export const projectEditPagePath = "/create-project/:projectId";

const calculateSuggestedPressure = (currency: Currency, productionType: ProductionType): number | undefined => {
  switch (productionType) {
    case ProductionType.Meat:
      return locale[currency].pressure2;
    case ProductionType.Vegetarian:
      return locale[currency].pressure2;
    case ProductionType.Bakery:
      return locale[currency].pressure1;
    case ProductionType.Beverage:
      return locale[currency].pressure1;
    case ProductionType.PreparedFood:
      return locale[currency].pressure1;
    case ProductionType.Dairy:
      return locale[currency].pressure1;
    case ProductionType.Poultry:
      return locale[currency].pressure2;
    case ProductionType.Fish:
      return locale[currency].pressure2;
    case ProductionType.Other:
      return locale[currency].pressure1;
  }
};

const ProjectCreationPage = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [isCopyOperation, setIsCopyOperation] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [currency, setCurrency] = useState<Currency>();
  const [projectType, setProjectType] = useState<ProjectType>(ProjectType.OfferWithRoi);
  const [projectName, setProjectName] = useState<string>();
  const [customerId, setCustomerId] = useState<string>();
  const [productionType, setProductionType] = useState<ProductionType>();
  const [productionAmount, setProductionAmount] = useState<number>();
  const [productionUnit, setProductionUnit] = useState<string>();
  const [currentWaterPressure, setCurrentWaterPressure] = useState<number>();
  const [currentWaterConsumption, setCurrentWaterConsumption] = useState<number>();
  const [currentHeatedWaterCost, setCurrentHeaterWaterCost] = useState<number>();
  const [currentWasteWaterCost, setCurrentWasteWaterCost] = useState<number>();
  const [currentNumberOfChemicals, setCurrentNumberOfChemicals] = useState<number>();
  const [containersPurchasedToday, setContainersPurchasedToday] = useState(false);
  const [currentChemicals, setCurrentChemicals] = useState<Chemical[]>([]);
  const [currentNumberOfCleaningSessions, setCurrentNumberOfCleaningSessions] = useState<number>();
  const [currentNumberOfCleaners, setCurrentNumberOfCleaners] = useState<number>();
  const [currentCleaningSessionDuration, setCurrentCleaningSessionDuration] = useState<number>();
  const [currentFlushingDuration, setCurrentFlushingDuration] = useState<number>();
  const [currentCleanerCost, setCurrentCleanerCost] = useState<number>();
  const [waterPressure, setWaterPressure] = useState<number>();
  const [dosing, setDosing] = useState<Dosing>();
  const [numberOfChemicals, setNumberOfChemicals] = useState<number>();
  const [ccu, setCcu] = useState<boolean>(false);
  const [numberOfStations, setNumberOfStations] = useState<number>();
  const [numberOfUsersOnWater, setNumberOfUsersOnWater] = useState<number>();
  const [numberOfUsersOnChemicals, setNumberOfUsersOnChemicals] = useState<number>();
  const [expansion, setExpansion] = useState<number>(0);
  const [timeSavingsWithCcs, setTimeSavingsWithCcs] = useState<number | undefined>(10);
  const [lts, setLts] = useState(true);
  const [pipes, setPipes] = useState(true);
  const [installation, setInstallation] = useState(true);
  const [serviceAgreement36Months, setServiceAgreement36Months] = useState(true);
  const [plans, setPlans] = useState("");

  const { projectId } = useParams<{ projectId?: string }>();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    CustomerService.getCustomers()
      .then((customers) => {
        setCustomers(customers);
      })
      .catch(() => {
        setCustomers([]);
      });
  }, []);

  useEffect(() => {
    if (currency === Currency.Dollar) {
      setTimeSavingsWithCcs(20);
    } else {
      setTimeSavingsWithCcs(10);
    }
  }, [currency]);

  useEffect(() => {
    if (projectId) {
      setLoading(true);
      const isCopy = location.pathname.includes("copy");
      setIsCopyOperation(isCopy);
      const fetchProjectData = async () => {
        return await ProjectsService.getProject(projectId);
      };

      fetchProjectData()
        .then((project) => {
          setProjectName(project.project.name);
          setProjectType(project.project.type);
          setCustomerId(project.project.customer);
          setCurrency(project.project.currency);
          setProductionType(project.project.productionType);
          setWaterPressure(project.project.waterPressure);
          setDosing(project.project.dosing);
          setNumberOfChemicals(project.project.numberOfChemicals);
          setCcu(project.project.ccu);
          setNumberOfStations(project.project.numberOfSatelliteStations);
          setNumberOfUsersOnWater(project.project.numberOfUsersOnWater);
          setNumberOfUsersOnChemicals(project.project.numberOfUsersOnChemicals);
          setExpansion(project.project.expansion);
          setLts(project.project.lts);
          setPipes(project.project.pipes);
          setInstallation(project.project.installation);
          setServiceAgreement36Months(project.project.serviceAgreement36Months);
          if (project.project.roiData) {
            setProductionAmount(project.project.roiData.productionAmount);
            setProductionUnit(project.project.roiData.productionUnit);
            setCurrentWaterPressure(project.project.roiData.currentWaterPressure);
            setCurrentWaterConsumption(project.project.roiData.currentWaterConsumption);
            setCurrentHeaterWaterCost(project.project.roiData.heatedWaterCost);
            setCurrentWasteWaterCost(project.project.roiData.wasteWaterCost);
            setCurrentNumberOfChemicals(project.project.numberOfChemicals);
            setContainersPurchasedToday(project.project.roiData.containersPurchasedToday);
            setCurrentChemicals(project.project.roiData.chemicals);
            setCurrentNumberOfCleaningSessions(project.project.roiData.numberOfCleaningSessions);
            setCurrentNumberOfCleaners(project.project.roiData.numberOfCleaners);
            setCurrentCleaningSessionDuration(project.project.roiData.cleaningSessionDuration);
            setCurrentFlushingDuration(project.project.roiData.flushingDuration);
            setCurrentCleanerCost(project.project.roiData.cleanerCost);
          }

          setLoading(false);
        })
        .catch((error) => {
          // Handle error. Maybe set an error state and display it.
          console.error("An error occurred while fetching the project:", error);
          setLoading(false);
        });
    }
  }, [projectId]); // Add projectId as a dependency so the effect runs again if it changes

  const handleDosingChange = (value: Dosing) => {
    setDosing(value);

    if (value === Dosing.Decentral) {
      setTimeSavingsWithCcs(0);
    } else if (value === Dosing.Central) {
      setTimeSavingsWithCcs(10);
    }
  };

  const isPreviousDisabled = () => {
    return currentStep === 0;
  };

  const isNextDisabled = (step: number): boolean => {
    switch (projectType) {
      case ProjectType.Offer:
        switch (step) {
          case 0:
            return [projectName, customerId, productionType, currency, projectType].some((e) => e === undefined);
          case 1:
            return waterPressure === undefined;
          case 2:
            return dosing === undefined;
          case 3:
            return numberOfChemicals === undefined;
          case 4:
            return numberOfStations === undefined;
          case 5:
            return numberOfUsersOnWater === undefined || dosing === "central"
              ? numberOfUsersOnChemicals === undefined
              : false;
          case 6:
            return (
              lts === undefined ||
              pipes === undefined ||
              installation === undefined ||
              serviceAgreement36Months === undefined
            );
          default:
            return false;
        }
      case ProjectType.OfferWithRoi:
        switch (currentStep) {
          case 0:
            return [projectName, customerId, productionType, currency, projectType].some((e) => e === undefined);
          case 1:
            return (
              currentWaterPressure === undefined ||
              currentWaterConsumption === undefined ||
              currentHeatedWaterCost === undefined ||
              currentWasteWaterCost === undefined
            );
          case 2:
            if (numberOfChemicals === undefined) return true;
            // if (currentChemicals.map((c) => Object.values(c)).flat().some((c) => c === undefined)) return true;
            return false;
          case 3:
            return (
              currentNumberOfCleaningSessions === undefined ||
              currentNumberOfCleaners === undefined ||
              currentCleaningSessionDuration === undefined ||
              currentFlushingDuration === undefined ||
              currentCleanerCost === undefined
            );
          case 4:
            return waterPressure === undefined;
          case 5:
            return dosing === undefined;
          case 6:
            return numberOfChemicals === undefined;
          case 7:
            return numberOfStations === undefined;
          case 8:
            return numberOfUsersOnWater === undefined;
          case 9:
            return timeSavingsWithCcs === undefined;
          case 10:
            return (
              lts === undefined ||
              pipes === undefined ||
              installation === undefined ||
              serviceAgreement36Months === undefined
            );
          default:
            return false;
        }
      default:
        return false;
    }
  };

  const goToPreviousStep = () => {
    setCurrentStep((step) => step - 1);
  };

  const submitStep = async () => {
    if (currentStep === stepTitles.length - 1) {
      const projectId = await createProject();
      console.log(projectId);
    } else {
      setCurrentStep((step) => step + 1);
    }
  };

  const handleProjectTypeChange = (value: ProjectType) => {
    setProjectType(value);
  };

  const handleProductionTypeChange = (value: ProductionType) => {
    setProductionType(value);
    if (currency === undefined) return; // Don't recalculate if currency not set
    setWaterPressure(calculateSuggestedPressure(currency, value));
  };

  const handleCurrentNumberOfChemicalsChange = (value?: number) => {
    setCurrentNumberOfChemicals(value);
    setNumberOfChemicals(value);
    setCurrentChemicals((prev) => {
      if (value === undefined) return prev;
      if (value < prev.length) {
        return prev.slice(0, value);
      } else {
        return [
          ...prev,
          ...[...Array(value - prev.length).keys()].map(() => ({ consumption: undefined, cost: undefined })),
        ];
      }
    });
  };

  const createProject = () => {
    const roiData = {
      productionAmount: productionAmount ?? 0,
      productionUnit: productionUnit ?? "",
      currentWaterPressure: currentWaterPressure ?? 0,
      suggestedWaterPressure: waterPressure ?? 0,
      numberOfCleaningSessions: currentNumberOfCleaningSessions ?? 0,
      numberOfCleaners: currentNumberOfCleaners ?? 0,
      cleaningSessionDuration: currentCleaningSessionDuration ?? 0,
      flushingDuration: currentFlushingDuration ?? 0,
      currentWaterConsumption: currentWaterConsumption ?? 0,
      heatedWaterCost: currentHeatedWaterCost ?? 0,
      wasteWaterCost: currentWasteWaterCost ?? 0,
      timeSavingsWithCcs: timeSavingsWithCcs ?? 0,
      chemicals: currentChemicals ?? { consumption: 0, cost: 0 },
      containersPurchasedToday,
      cleanerCost: currentCleanerCost !== undefined ? currentCleanerCost : 0,
    };

    const projectData: CreateProjectRequestBody = {
      name: projectName!,
      type: projectType,
      customer: customerId!,
      currency: currency!,
      productionType: productionType!,
      waterPressure: waterPressure!,
      dosing: dosing!,
      numberOfChemicals: numberOfChemicals!,
      numberOfSatelliteStations: numberOfStations!,
      numberOfUsersOnWater: numberOfUsersOnWater!,
      numberOfUsersOnChemicals: numberOfUsersOnChemicals!,
      ccu: ccu,
      expansion: expansion!,
      lts: lts,
      pipes: pipes,
      pipesCostPercentage: 0, // Is set by backend
      installation: installation,
      paymentPlan: null,
      installationCostPercentage: 0, // Is set by backend
      serviceAgreement36Months: serviceAgreement36Months,
      serviceCostPercentage: 0, // Is set by backend
      fixedPrice: -1,
      probability: 0,
    };

    if (projectType === ProjectType.OfferWithRoi) {
      projectData.roiData = roiData;
    }

    setSubmitting(true);
    ProjectsService.createProject(projectData)
      .then((newProjectId) => {
        // If projectId is defined, delete the old project
        if (projectId && !isCopyOperation) {
          ProjectsService.deleteProject(projectId)
            .then(() => {
              // Successfully deleted the old project
              // Now navigate to the new project
              navigate(generatePath(projectPagePath, { projectId: newProjectId }));
            })
            .catch((error) => {
              // Handle error while deleting
            });
        } else {
          // projectId is undefined, so just navigate to the new project
          navigate(generatePath(projectPagePath, { projectId: newProjectId }));
        }
      })
      .catch(() => {})
      .finally(() => setSubmitting(false));
  };

  const stepTitles = [
    { long: "Project context", short: "Start" },
    ...(projectType === ProjectType.OfferWithRoi
      ? [
          { long: "Current water consumption", short: "Water" },
          { long: "Current chemicals consumption", short: "Chemicals" },
          { long: "Current labour consumption", short: "Labour" },
        ]
      : []),
    { long: "Lagafors system water configuration", short: "Pressure" },
    { long: "Lagafors system dosing configuration", short: "Dosing" },
    { long: "Lagafors system chemicals configuration", short: "\u2116 chem" },
    { long: "Lagafors system stations configuration", short: "Stations" },
    { long: "Lagafors system users configuration", short: "Users" },
    ...(projectType === ProjectType.OfferWithRoi ? [{ long: "Lagafors system savings", short: "Time" }] : []),
    { long: "Lagafors system recommendations", short: "Misc" },
  ];
  const isLastStep = currentStep === stepTitles.length - 1;

  const steps = [
    <BaseInfoStep
      initial={{
        projectName,
        projectType,
        customer: customerId,
        productionType,
        currency,
        productionAmount,
        productionUnit,
      }}
      customers={customers}
      onProjectNameChange={setProjectName}
      onProjectTypeChange={handleProjectTypeChange}
      onCustomerChange={setCustomerId}
      onProductionTypeChange={handleProductionTypeChange}
      currency={currency}
      onCurrencyChange={setCurrency}
      onProductionAmountChange={setProductionAmount}
      onProductionUnitChange={setProductionUnit}
    />,
    ...(projectType === ProjectType.OfferWithRoi
      ? [
          <CurrentWaterStep
            initial={{
              waterPressure: currentWaterPressure,
              waterConsumption: currentWaterConsumption,
              heatedWaterCost: currentHeatedWaterCost,
              wasteWaterCost: currentWasteWaterCost,
            }}
            currency={currency!}
            onWaterPressureChange={setCurrentWaterPressure}
            onWaterConsumptionChange={setCurrentWaterConsumption}
            onHeatedWaterCostChange={setCurrentHeaterWaterCost}
            onWasteWaterCostChange={setCurrentWasteWaterCost}
          />,
          <CurrentChemicalsStep
            initial={{
              numberOfChemicals: currentChemicals.length,
              containersPurchasedToday: containersPurchasedToday,
              ...(() => {
                const x: any = {};

                for (let i = 0; i < (currentChemicals.length || 0); i++) {
                  x[`consumption${i}`] = currentChemicals[i].consumption;
                  x[`cost${i}`] = currentChemicals[i].cost;
                }

                return x;
              })(),
            }}
            currency={currency!}
            onNumberOfChemicalsChange={handleCurrentNumberOfChemicalsChange}
            onContainersPurchasedTodayChange={setContainersPurchasedToday}
            currentChemicals={currentChemicals}
            onChemicalConsumptionChange={(i, value) => {
              const x = currentChemicals;
              x[i].consumption = value;
              setCurrentChemicals(x);
            }}
            onChemicalCostChange={(i, value) => {
              const x = currentChemicals;
              x[i].cost = value;
              setCurrentChemicals(x);
            }}
          />,
          <CurrentLabourStep
            initial={{
              numberOfCleaningSessions: currentNumberOfCleaningSessions,
              numberOfCleaners: currentNumberOfCleaners,
              cleaningSessionDuration: currentCleaningSessionDuration,
              flushingDuration: currentFlushingDuration,
              cleanerCost: currentCleanerCost,
            }}
            currency={currency!}
            onNumberOfCleaningSessionsChange={setCurrentNumberOfCleaningSessions}
            onNumberOfCleanersChange={setCurrentNumberOfCleaners}
            onCleaningSessionDurationChange={setCurrentCleaningSessionDuration}
            onFlushingDurationChange={setCurrentFlushingDuration}
            onCleanerCostChange={setCurrentCleanerCost}
          />,
        ]
      : []),
    <SystemWaterStep
      initial={{ waterPressure }}
      currency={currency!}
      productionType={productionType!}
      onWaterPressureChange={setWaterPressure}
    />,
    <SystemDosingStep initial={{ dosing }} onDosingChange={handleDosingChange} />,
    <SystemChemicalsStep initial={{ numberOfChemicals, dosing }} onNumberOfChemicalsChange={setNumberOfChemicals} />,
    <SystemStationsStep initial={{ numberOfStations }} onNumberOfStationsChange={setNumberOfStations} />,
    <SystemUsersStep
      initial={{
        numberOfUsersOnWater,
        numberOfUsersOnChemicals,
        expansion,
        dosing,
        numberOfChemicals,
        ccu,
        waterPressure,
      }}
      onNumberOfUsersOnWaterChange={setNumberOfUsersOnWater}
      onNumberOfUsersOnChemicalsChange={setNumberOfUsersOnChemicals}
      onExpansionChange={setExpansion}
      onDosingChange={setDosing}
      onNumberOfChemicals={setNumberOfChemicals}
      onCcuChange={setCcu}
    />,
    ...(projectType === ProjectType.OfferWithRoi
      ? [<SystemSavingsStep initial={{ timeSavingsWithCcs }} onTimeSavingsWithCcsChange={setTimeSavingsWithCcs} />]
      : []),
    <SystemServiceStep
      lts={lts}
      pipes={pipes}
      installation={installation}
      serviceAgreement36Months={serviceAgreement36Months}
      plans={plans}
      onLtsChange={setLts}
      onPipesChange={setPipes}
      onInstallationChange={setInstallation}
      onServiceAgreement36MonthsChange={setServiceAgreement36Months}
      onPlansChange={setPlans}
    />,
  ];

  if (loading) {
    return (
      <div className="spinner-container">
        <Spin tip="Loading project data" />
      </div>
    );
  }

  return (
    <Page className="project-creation-page">
      <Header selectedMenuOption={null} />
      <div className="content">
        <Steps
          className="steps"
          current={currentStep}
          size="small"
          labelPlacement="vertical"
          onChange={(c) => setCurrentStep(c)}
          items={stepTitles.map((title, idx) => ({
            title: title.short,
            disabled: isNextDisabled(idx - 1),
          }))}
        />
        <Card
          className="step"
          title={stepTitles[currentStep].long}
          extra={
            <div className="step-extra">
              <Button disabled={isPreviousDisabled()} onClick={goToPreviousStep}>
                Previous
              </Button>
              <Button
                type={isLastStep ? "primary" : "default"}
                disabled={isNextDisabled(currentStep)}
                loading={submitting ? { delay: 500 } : false}
                onClick={submitStep}
              >
                {isLastStep ? "Save" : "Next"}
              </Button>
            </div>
          }
        >
          {steps[currentStep]}
        </Card>
      </div>
    </Page>
  );
};

export default ProjectCreationPage;
